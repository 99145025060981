import React, { useMemo, useState, CSSProperties, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Image,
} from "semantic-ui-react";

import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";

import Cookies from "js-cookie";

const LANGUAGE = ["th", "th-TH", "th-th"].includes(
  Cookies.get("language") || navigator?.language?.split(/[-_H]/)[0]
)
  ? "th-TH"
  : "en-US";

type CardAllergyWarningProps = {
  onEvent: any;
  history?: any;
  allergyList?: any[];
  href?: string;
  // config
  isImplant?: boolean;
  mode?: "swipeable";
  detailField: string | string[];
  hospitalField?: string;
  type?: "allergy" | "warning" | "implant";
};

// Use styles
const COLOR = {
  primary: "var(--primary-theme-color)",
  light_red: "rgba(235, 87, 87, 0.2)",
  light_yellow: "rgba(237, 192, 57, 0.2)",
  dark_grey: "rgba(159, 159, 159, 1)",
  super_light: "rgba(233, 233, 233, 1)",
  red: "#DA0000",
  grey: "#4F4F4F",
  // Swipeable
  darkgrayHos: "#797878",
};

const useStyles = makeStyles(() => ({
  card_bottom: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    bottom: 0,
    left: 0,
    padding: "0 18px 12px",
    width: "100%",
    "& > div": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "15px",
    },
    "& > svg": {
      fontSize: "1.75rem",
      margin: "-2px -10px",
    },
  },
  stepper: {
    minHeight: "24px",
    "& .MuiMobileStepper-root": {
      transform: "scale(1.1)",
      margin: "0px 0px 5px",
      justifyContent: "center",
    },
    "&.--allergy": {
      "& .MuiMobileStepper-dotActive": {
        backgroundColor: "#DA3535",
      },
    },
    "&.--warning": {
      "& .MuiMobileStepper-dotActive": {
        backgroundColor: "#FFD600",
      },
    },
    "&.--implant": {
      "& .MuiMobileStepper-dotActive": {
        backgroundColor: "var(--primary-theme-color)",
      },
    },
  },
}));

const styles = {
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0px 20px",
    color: COLOR.primary,
  } as CSSProperties,
  allergy_warning_box: {
    backgroundColor: COLOR.light_red,
    display: "grid",
    gridTemplateColumns: "34px 1fr",
    borderRadius: "5px",
    width: "100%",
    padding: "15px 15px",
    marginBottom: "20px",
  } as CSSProperties,
  message: {
    paddingLeft: "1.75rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2 /* number of lines to show */,
    lineClamp: 2,
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  // Swipeable
  card: {
    width: "100%",
    height: "112px",
    borderRadius: "10px",
    padding: "15px 20px",
    position: "relative",
  } as CSSProperties,
  text_gray: {
    fontSize: "14px",
    fontFamily: "Montserrat, Noto Sans Thai",
    fontWeight: "700",
    color: "#797878",
  } as CSSProperties,
};

// Images
const ICONS = {
  warning_red: "/images/History/warning-red-icon.png",
  warning_yellow: "/images/History/warning-yellow-icon.png",
  info_red: "/images/History/info-red-icon.png",
  info_yellow: "/images/History/info-yellow-icon.png",
  icon_right_arrow: "/images/icon_right_arrow.png",
};

const getValue = (item: any, key: string | string[]) => {
  return Array.isArray(key)
    ? item[key.find((value) => item[value]) || ""]
    : item[key];
};

const joinImplant = (list: any[]) => {
  return list.map((item, index) => (
    <div
      key={index}
      style={{
        whiteSpace: "nowrap", // Prevent wrapping
        overflow: "hidden",  // Hide overflowing content
        textOverflow: "ellipsis", // Show ellipsis for overflow
      }}
    >
      Implant {index + 1}: {getValue(item, ["imP_DESC"])}
    </div>
  ));
};

const CardAllergyWarning: React.FC<CardAllergyWarningProps> = (props) => {
  const data = useMemo(() => {
    const items: any[] = props.allergyList || [];
    const [allergy, warning, implant] = items.reduce<any[][]>(
      (result, item) => {
        let type: string = props.type || item.type || "";
        type = type.split("-")[0];

        if (props.isImplant) {
          type = "implant";
        }

        const index = ({ allergy: 0, warning: 1, implant: 2 } as any)[type];
        result[index ?? 3].push(item);
        return result;
      },
      [[], [], [], []] as any[][]
    );

    const joinText = (list: any[]) => {
      return list.map((item) => getValue(item, props.detailField)).join(", ");
    };

    return {
      allergy,
      allergyText: joinText(allergy),
      warning,
      warningText: joinText(warning),
      implant,
      implantText: joinText(implant),
    };
  }, [props.allergyList]);

  const setting = useMemo(() => {
    const key = data.allergyText
      ? "allergy"
      : data.warningText
        ? "warning"
        : data.implantText
          ? "implant"
          : "no_known";

    return {
      allergy: {
        bg: COLOR.light_red,
        color: COLOR.red,
        title: `Allergy: `,
        content: data.allergyText,
        info: ICONS.info_red,
        warning: ICONS.warning_red,
      },
      warning: {
        bg: COLOR.light_yellow,
        color: COLOR.grey,
        title: `Warning: `,
        content: data.warningText,
        info: ICONS.info_yellow,
        warning: ICONS.warning_yellow,
      },
      implant: {
        bg: COLOR.primary,
        color: "white",
        title: `Warning: `,
        content: data.implantText,
        info: ICONS.info_yellow,
        warning: ICONS.warning_yellow,
      },
      no_known: {
        bg: COLOR.super_light,
        color: COLOR.dark_grey,
        content: "",
      },
    }[key];
  }, [data]);

  // Handle
  const handleClick = () => {
    if (setting.title) {
      props.history.push("/Allergy-Card/?app=MobMedication");
    }
  };

  const handleRef = (elm: any) => {
    handleLoadMessage(elm, setting.content);
  };

  return (
    <>
      {props.mode === "swipeable" ? (
        <SwipeableMode
          onEvent={props.onEvent}
          history={props.history}
          allergy={data.allergy}
          warning={data.warning}
          implant={data.implant}
          isImplant={props.isImplant || false}
          href={props.href || ""}
          detailField={props.detailField || ""}
          hospitalField={props.hospitalField || ""}
        />
      ) : (
        <div style={{ width: "100%", padding: "0 15px" }}>
          <div
            style={{
              ...styles.allergy_warning_box,
              display: setting.title ? "grid" : "",
              backgroundColor: setting.bg,
              color: setting.color,
              minHeight: "5rem",
            }}
            onClick={handleClick}
          >
            {setting.title ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      position: "relative",
                      height: "26px",
                      width: "34px",
                    }}
                  >
                    <img
                      src={setting.info}
                      alt="info.icon"
                      style={{ width: "26px" }}
                    />
                    <img
                      src={setting.warning}
                      alt="waring.icon"
                      style={{
                        width: "17px",
                        position: "absolute",
                        top: -7,
                        right: -7,
                      }}
                    />
                  </div>
                </div>
                <div ref={handleRef} style={styles.message}>
                  <span style={{ fontWeight: "bold" }}>{setting.title}</span>{" "}
                  <span>{""}</span>
                </div>
              </>
            ) : (
              <div style={{ color: COLOR.dark_grey, fontWeight: "bold" }}>
                -
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

/* ------------------------------------------------------ */

/*                      SwipeableMode                     */

/* ------------------------------------------------------ */
type SwipeableModeProps = {
  onEvent: any;
  allergy: any[];
  warning: any[];
  implant: any[];
} & Required<
  Pick<
    CardAllergyWarningProps,
    "detailField" | "hospitalField" | "history" | "href" | "isImplant"
  >
>;

const SwipeableMode = (props: SwipeableModeProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const classes = useStyles();

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const setting = useMemo(() => {
    const key = !!props.allergy.length
      ? "allergy"
      : !!props.warning.length
        ? "warning"
        : !!props.implant.length
          ? "implant"
          : "no_known";

    const groupByHospital = (items: any[]) => {
      // const group = items.reduce((result, item) => {
      //   const data = result[item[props.hospitalField]];

      //   result[item[props.hospitalField]] = !!data
      //     ? {
      //         ...data,
      //         [props.detailField]: [
      //           data[props.detailField],
      //           item[props.detailField],
      //         ].join(", "),
      //       }
      //     : item;

      //   return result;
      // }, {});

      // return Object.values(group) as any[];
      return items;
    };

    return {
      allergy: {
        bg: "#DA3535",
        title_color: "white",
        hospital_color: "white",
        items: groupByHospital(props.allergy),
        type: "allergy",
      },
      warning: {
        bg: "#FFD600",
        title_color: "#4F4F4F",
        hospital_color: "#797878",
        items: groupByHospital(props.warning),
        type: "warning",
      },
      implant: {
        bg: "#0147A3",
        title_color: "white",
        hospital_color: "white",
        items: groupByHospital(props.implant),
        type: "implant",
      },
      no_known: {
        bg: "white",
        title_color: "#797878",
        items: [],
        type: "no_known",
      },
    }[key];
  }, [props.allergy, props.warning, props.implant]);

  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  const handleClick = (item?: any) => {
    props.history.push(props.href);

    if (props.isImplant && item) {
      props.onEvent({
        message: "SelectedImplant",
        params: { item: item },
      });
    }
  };

  const handleRef = (text: string) => (elm: any) => {
    handleLoadMessage(elm, text);
  };

  console.log("setting.items", setting.items)

  return (
    <>
      {/* @ts-ignore */}
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleChangeStep}
        enableMouseEvents
      >
        {!!setting.items?.length ? (
          setting.items.map((item, index) => (
            <div key={"view-" + index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div style={{ margin: "15px" }} onClick={() => {
                  handleClick(item)
                }}>
                  <div style={{
                    ...styles.card,
                    backgroundColor: setting.bg,
                    height: props.isImplant ? "130px" : "112px"
                  }}>
                    <div>
                      <span
                        style={{
                          ...styles.text_gray,
                          color: setting.title_color,
                          fontSize: "16px",
                          fontWeight: "700", // Bold for "Procedure:"
                        }}
                      >
                        {props.isImplant? <>Procedure:&nbsp;</> : ""}
                      </span>
                      <span
                        ref={handleRef(
                          props.isImplant
                            ? `${item.procedurE_DESC}`
                            : getValue(item, props.detailField)
                        )}
                        style={{
                          ...styles.text_gray,
                          color: setting.title_color,
                          fontSize: "16px",
                          fontWeight: "500", // Regular weight for detail text
                        }}
                      >
                        {props.isImplant
                          ? `${item.procedurE_DESC}`
                          : getValue(item, props.detailField)}
                      </span>
                    </div>
                    <div
                      style={{
                        color: setting.hospital_color,
                        fontFamily: "Montserrat, Noto Sans Thai",
                        fontSize: "13px",
                        fontWeight: "500",
                        marginTop: "8px",
                      }}
                    >
                      {props.isImplant ? joinImplant(item?.implant) : ""}
                    </div>
                    {props.isImplant ? (<></>) :
                      (<>
                        <Box className={classes.card_bottom} style={{}}>
                          <div
                            style={{
                              color: setting.hospital_color,
                              fontFamily: "Montserrat, Noto Sans Thai",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {item[props.hospitalField]}
                          </div>
                          <KeyboardArrowRightIcon
                            style={{ color: setting.title_color }}
                          />
                        </Box>
                      </>)
                    }

                  </div>
                </div>
              ) : null}
            </div>
          ))
        ) : (
          <div style={{ margin: "15px" }}>
            <div style={{ ...styles.card, backgroundColor: "#FFFFFF", height: "auto" }}>
              <div
                style={{
                  ...styles.text_gray,
                  backgroundColor: setting.bg,
                  color: setting.title_color,
                  textAlign: "center",
                }}
              >
                {props.isImplant ?
                  (LANGUAGE === "en-US") ? "No Implant" : "ไม่มีข้อมูล Implant" :
                  (LANGUAGE === "en-US") ? "-" : "-"}
              </div>
              <Image
                src={props.isImplant ? "/images/History/no-implant.png" : ""}
                style={{ marginTop: "10px" }}
                centered
              />
            </div>
          </div>
        )}
      </SwipeableViews>

      <Box
        className={`${classes.stepper} --${setting.type}`}
        style={{ display: setting.items.length < 0 ? "none" : "" }}
      >
        <MobileStepper
          steps={setting.items.length}
          position="static"
          activeStep={activeStep}
          nextButton={""}
          backButton={""}
        />
      </Box>
    </>
  );
};

// Utils
const handleLoadMessage = (elm: HTMLDivElement, text: string) => {
  if (elm) {
    const cssObj = globalThis.getComputedStyle(elm, null);
    const fontSize = cssObj.getPropertyValue("font-size").match(/^\d+/g)?.[0];
    const limit = (elm.offsetWidth * 2) / (Number(fontSize) / 1.55);

    const content = text
      ?.split(",")
      ?.reduce((result: string, value: string) => {
        return result.length < limit
          ? result.length
            ? [result, value].join(",").substring(0, limit)
            : value
          : result;
      }, "");

    const children = elm.children;
    const target = (
      !!children.length ? children[children.length - 1] : elm
    ) as HTMLElement;

    target.innerHTML = text?.length > limit ? `${content}...` : text;
  }
};

export default React.memo(CardAllergyWarning);
